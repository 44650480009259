import "./style.scss";

import { useRef } from "react";

import PropTypes from "prop-types";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { createClassName } from "../../helpers";

// import loadable from '@loadable/component'

// const Sticker = loadable(() => import(/* webpackPrefetch: true */ "react-stickyfill"))

const cn = createClassName("sidebar");

const Sidebar = ({ children, position, className }) => {
  const { isMobileOnly } = useLocaleContext();
  const sidebarRef = useRef(null);

  // useEffect(() => {
  //   const applyStickyFill = () => {
  //     if (sidebarRef.current && window.innerWidth > 768) {
  //       return sidebarRef.current;
  //     }
  //   };
  //   window.addEventListener(
  //     "resize",
  //     () => applyStickyFill(),
  //     false
  //   );
  //   return window.removeEventListener("resize", () => applyStickyFill(), false);
  // }, []);

  return (
    <>
      {!isMobileOnly && (
        // <Sticker>
        <aside
          aria-label="side-bar"
          ref={sidebarRef}
          className={`${cn("wrap", {
            position,
            "fix-side-bar": true,
          })} ${className}`}
        >
          {children}
        </aside>
        // </Sticker>
      )}
    </>
  );
};

Sidebar.defaultProps = {
  className: "",
  position: "right",
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  position: PropTypes.string,
};

export default Sidebar;
