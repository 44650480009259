/* eslint-disable react/prop-types */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import i18next from "../../i18n";
import useWindowSize from "../../helpers/useWindowSize";

const LocaleContext = createContext({
  localePath: "",
});

export const useLocaleContext = () => useContext(LocaleContext);

export const LocaleProvider = ({
  children,
  locale,
  siteLanguage,
  localePath,
}) => {
  useEffect(() => {
    i18next.changeLanguage(siteLanguage);
  }, [siteLanguage]);

  const mobile = useWindowSize();
  const mobileOnly = useWindowSize(550);

  const value = useMemo(() => ({
    locale,
    siteLanguage,
    localePath,
    isMobile: mobile,
    isMobileOnly: mobileOnly,
  }), [locale, localePath, mobile, mobileOnly, siteLanguage]);

  return (
    <LocaleContext.Provider value={value}>
      {children}
    </LocaleContext.Provider>
  );
};
