import "./404.scss";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import { LazyImage } from "../components/Image";

import AccentSecondaryBanner from "../components/AccentBanner/AccentSecondaryBanner";
import Container from "../components/Container";
import Layout from "../layouts";
import Link from "../components/Links/LinkOnPage";
import { useLocaleContext } from "../contexts/LocaleContext";
import Title from "../components/Title";
import { createClassName } from "../helpers";

// import Frame from "../layouts/Frame";

const cn = createClassName("page404");

const pages = (list) => list.map((item, index) => (
  <li key={index} className={cn({ list: "item" })}>
    <Link
      href={item.to}
      className={cn({ list: "item-link" })}
      color="primary-main"
    >
      {item.page}
    </Link>
  </li>
));

const Page404 = ({ data }) => {
  const { isMobileOnly } = useLocaleContext();
  // const [openFrame, freeTrial] = useState(false);
  const { t } = useTranslation("page404");

  // const handler = () => {
  //   freeTrial(true);
  //   const trialBlock = document.querySelector("#get-started");
  //   trialBlock.scrollIntoView({ behavior: "smooth" });
  // };

  return (
    <Layout
      title={t("seo.title")}
      description=""
      noForRobots
      // freeTrialButton
      // buttonHandler={handler}
      isCanonical={false}
    >
      <section aria-label="404: Page Not found">
        <Container>
          <div className={cn({ wrap: true })}>
            <div
              className={cn("block", {
                text: true,
                isMobileOnly,
              })}
            >
              <Title level={1} color="primary-main" weight="bold">
                {t("heading")}
              </Title>
              <Title
                level={4}
                className={cn({ subtitle: true })}
                color="default"
              >
                {t("title")}
              </Title>
              <ul className={cn({ list: true })}>
                <Title level={4} weight="normal">
                  {t("page-list.title")}
                </Title>
                {pages(t("page-list.list", { returnObjects: true }))}
              </ul>
            </div>
            {!isMobileOnly && (
              <div className={cn("block", { img: true })}>
                <div className={cn("block", { img: "wrap" })}>
                  <LazyImage
                    image={data.paperclipImage.childImageSharp}
                    aria-hidden="true"
                    alt={t("alt")}
                    eager
                  />
                  <LazyImage
                    image={data.banner404.childImageSharp}
                    className={cn("block", { img: "icon" })}
                    alt=""
                    eager
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
        <section aria-label="Get started in no time" id="get-started">
          <AccentSecondaryBanner
            // handler={handler}
            subtitleClassName={cn("banner", { subtitle: true })}
            buttonClassName={cn("banner", { btn: true })} // delete for button
          />
          {/* <Frame
            open={openFrame}
          /> */}
        </section>
      </section>
    </Layout>
  );
};

export default Page404;

export const query = graphql`
  query get404Page{    
    banner404:file(
      relativePath:{
        eq: "images/icons/paperClip404.png"
      }
    )
    {
      ...noBase64FluidImage
    }
    paperclipImage:file(
      relativePath:{
        eq: "images/icons/paperclip-404-error.png"
      }
    )
    {
      ...noBase64FluidImage
    }
  }
`;
