import { useEffect, useState } from "react";

const useWindowSize = (breakPoint = 812) => {
  const [lessThenBreakPoint, setlessThenBreakPoint] = useState(true);

  const handleMediaQueryChanges = (e) => setlessThenBreakPoint(e.matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakPoint}px)`);
    handleMediaQueryChanges(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChanges);
    return () => mediaQuery.removeListener(handleMediaQueryChanges);
  }, []);

  return lessThenBreakPoint;
};

export default useWindowSize;
