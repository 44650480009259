import "./style.scss";

import { useState } from "react";
import * as React from "react";
import { createClassName } from "../../../helpers";

import ArrowLeft from "../../Icons/ArrowLeft";
import ArrowRight from "../../Icons/ArrowRight";
import PropTypes from "prop-types";
import Testimonial from "..";
import loadable from "@loadable/component";

const cn = createClassName("containerForTestimonials");

// const Slider = loadable(() => import(/* webpackPrefetch: true */ "react-slick/lib/index"))

// import Slider from "react-slick";
import LazyLoad from "react-lazy-load";

const ArrowButton = props => {
  return (
    <button
      onClick={props.onClick}
      className={cn("arrow", {
        visible: `${props.disabled}`,
        [props.duration]: true,
      })}
      aria-label={"move list " + props.duration}
    >
      <>{props.duration === "left" ? <ArrowLeft /> : <ArrowRight />}</>
    </button>
  );
};

const testimonialsList = list =>
  list.map((testimonial, index) => (
    <div key={index} className={cn("item")}>
      <Testimonial
        item={testimonial.item}
        author={testimonial.author}
        skill={testimonial.skill}
      />
    </div>
  ));


const ContainerForTestimonials = props => {
  const [isStubed, setIsStubed] = React.useState(true);
  const Slider = React.useMemo(
    () =>
      loadable(() =>
        import("react-slick/lib/index").then(slider => {
          setIsStubed(false);
          return slider;
        })
      ),
    []
  );

  const [position, setPosition] = useState(0);
  const { isMobile } = React.useContext(LocaleContext);
  const slidesToShow = isMobile ? 1 : 2;

  const settingsSwipe = {
    infinite: false,
    speed: 500,
    nextArrow: (
      <ArrowButton
        duration="right"
        disabled={position !== props.testimonialsList.length - slidesToShow}
      />
    ),
    prevArrow: <ArrowButton duration="left" disabled={position !== 0} />,
    afterChange: index => setPosition(index),
    beforeChange: index => setPosition(index),
    onSwipe: index => setPosition(index),
    responsive: [
      {
        breakpoint: 2048,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToShow,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToShow,
        },
      },
    ],
    className: cn("slider", { wrap: true }),
    draggable: false,
    slide: "li",
  };

  const stubTestimonialsList = (
    <div className={cn("stubTestimonialList")}>
      {testimonialsList([props.testimonialsList[0], props.testimonialsList[1]])}
    </div>
  );

  return (
    <section aria-label="Testimonials section">
      {isStubed && stubTestimonialsList}
      <LazyLoad throttle={0} offsetVertical={100} once={true}>
        <div className={cn("slider")}>
          <Slider {...settingsSwipe}>
            {testimonialsList(props.testimonialsList)}
          </Slider>
        </div>
      </LazyLoad>
    </section>
  );
};

ContainerForTestimonials.propTypes = {
  testimonialsList: PropTypes.arrayOf(PropTypes.object),
};

export default ContainerForTestimonials;
