import "./style.scss";

import {
  EmailShareButton,
  // FacebookShareButton,
  TwitterShareButton,
  VKShareButton,
} from "react-share/es/";

import PropTypes from "prop-types";
import { createElement } from "react";
import SocialIcon from "../Icons/SocialIcon";
import { createClassName } from "../../helpers";
import { TARGET_LANGUAGE } from "../../../config/i18n";

const cn = createClassName("social-network");

const SocialNetwork = (props) => {
  const socialsList = (TARGET_LANGUAGE === "ru") ? {
    twitter: {
      link: props.link,
      component: TwitterShareButton,
      data: {
        title: props.title,
        // via: "string",
        hashtags: props.hashtags,
      },
    },
    vk: {
      link: props.link,
      component: VKShareButton,
      data: {
        title: props.title,
        // image: "An absolute link to the image that will be shared",
        // noParse: false, //"If true is passed, VK will not retrieve URL information",
        // noVkLinks: false, //"If true is passed, there will be no links to the user's profile in the open window. Only for mobile devices",
      },
    },
    mail: {
      link: props.link,
      component: EmailShareButton,
      data: {
        subject: props.title,
        // body: props.link, // "Email, will be prepended to the url.",
        separator: "", // "Separates body from the url",
      },
    },
  } : {
    // fb: {
    //   link: props.link,
    //   component: FacebookShareButton,
    //   data: {
    //     // quote: A quote to be shared along with the link
    //     hashtag: props.hashtags.map((item) => `#${String(item)}`),
    //   },
    // },
    twitter: {
      link: props.link,
      component: TwitterShareButton,
      data: {
        title: props.title,
        // via: "string",
        hashtags: props.hashtags,
      },
    },
    mail: {
      link: props.link,
      component: EmailShareButton,
      data: {
        subject: props.title,
        // body: props.link, // "Email, will be prepended to the url.",
        separator: "", // "Separates body from the url",
      },
    },
  };

  return Object.keys(socialsList).map((key, index) => (
    <div className={cn("wrap")} key={index}>
      {/* {console.log(props.link)} */}
      {createElement(
        socialsList[key].component,
        {
          className: `${cn("link")}`,
          url: socialsList[key].link,
          ...socialsList[key].data,
        },
        <SocialIcon icon={key} />,
      )}
    </div>
  ));
};

SocialNetwork.propTypes = {
  title: PropTypes.string.isRequired,
  hashtags: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default SocialNetwork;
