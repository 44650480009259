/* eslint-disable react/prop-types */
import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { promocodeRequest } from "./promocodeRequest";
import { TARGET_LANGUAGE } from "../../../config/i18n";

const { getParts } = require("../../helpers/getParts");

const ProductContext = createContext();
export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allProduct {
        products: nodes {
          productCode
          unitCostInteger
          unitCostDecimal
          promotionCostInteger
          promotionCostDecimal
          upgradeCostInteger
          upgradeCostDecimal
          currency
          discounts {
            minQuantity
            discount
          }
        }
      }
      promotion {
        reason
      }
    }
  `);

  const { allProduct, promotion } = data;

  const { banner, isSale, isPromoBanner } = useMemo(() => {
    let banner, isSale, isPromoBanner;
    switch (promotion.reason) {
      case "none":
        isSale = false;
        isPromoBanner = false;
        break;
      case "ChristmasSale50":
        isSale = true;
        isPromoBanner = true;
        banner = "christmas50";
        break;
      case "ChristmasSale60":
        isSale = true;
        isPromoBanner = true;
        banner = "christmas60";
        break;
      case "FlashSale":
        isSale = true;
        isPromoBanner = true;
        banner = "flashSale";
        break;
      case "CyberMonday":
        isSale = true;
        isPromoBanner = true;
        banner = "cyberMonday";
        break;
      case "BlackFriday":
        isSale = true;
        isPromoBanner = true;
        banner = "blackFriday";
        break;
      case "NewVersion":
        isSale = false;
        isPromoBanner = true;
        banner = "newVersion";
        break;
      default:
        isSale = false;
        isPromoBanner = false;
        break;
    }
    return { banner, isSale, isPromoBanner };
  }, [promotion.reason]);

  const [isCookiesBanner, setCookiesBanner] = useState(true);
  const [productList, setProductList] = useState(allProduct);
  const [isSaleCompany, setSaleCompany] = useState(isSale);
  const [promocodeName, setPromocodeName] = useState(null);
  const [promocodeDescription, setPromocodeDescription] = useState(null);

  const resetPromocode = useCallback(() => {
    setSaleCompany(false);
    setPromocodeName(null);
    setProductList(allProduct);
  }, [allProduct]);

  const applyPromocode = async (
    promocode,
    setLoading,
    searchPromocode,
    setServerResponse,
  ) => {
    setLoading(true);
    try {
      const response = await promocodeRequest(`pricing/${TARGET_LANGUAGE}/${promocode}`);
      const items = response.price;
      const { description } = response.discount;
      const productsArray = items.map(({
        productCode, currency, price, discountedPrice, volumeDiscounts: discounts, upgradePrice,
      }) => {
        const { integerPart: unitCostInteger, decimalPart: unitCostDecimal } = getParts(price);
        const { integerPart: promotionCostInteger, decimalPart: promotionCostDecimal } = discountedPrice ? getParts(discountedPrice) : {};
        const { integerPart: upgradeCostInteger, decimalPart: upgradeCostDecimal } = upgradePrice ? getParts(upgradePrice) : {};
        return {
            productCode,
            unitCostInteger,
            unitCostDecimal,
            promotionCostInteger,
            promotionCostDecimal,
            upgradeCostInteger,
            upgradeCostDecimal,
            currency,
            discounts,
        };
      });
      const productsUpdate = {
        products: productsArray,
      };
      setProductList(productsUpdate);
      setPromocodeDescription(description);
      setSaleCompany(true);
      setPromocodeName(promocode);
    } catch (error) {
      if (error.status === 404) {
        searchPromocode(false);
      } else {
        setServerResponse(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const value = useMemo(() => ({
    productList,
    isSaleCompany,
    isPromoBanner,
    banner,
    isCookiesBanner,
    setCookiesBanner,
    promocodeName,
    applyPromocode,
    resetPromocode,
    promocodeDescription,
  }), [
    banner,
    isCookiesBanner,
    isPromoBanner,
    isSaleCompany,
    productList,
    promocodeName,
    resetPromocode,
    promocodeDescription,
  ]);

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
};
