import PropTypes from "prop-types";

import { useLocaleContext } from "../../../contexts/LocaleContext";

import { createClassName } from "../../../helpers";
import "./style.scss";

const cn = createClassName("content-with-sidebar");

const ContentWithSidebar = ({ children, className }) => {
  const { isMobileOnly } = useLocaleContext();
  return (
    <div className={`${cn({ mobile: isMobileOnly })} ${className}`}>
      {children}
    </div>
  );
};

ContentWithSidebar.defaultProps = {
  className: "",
};

ContentWithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ContentWithSidebar;
