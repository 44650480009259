/* eslint-disable react/prop-types */

import { YMInitializer } from "react-yandex-metrika/src";
import { TARGET_LANGUAGE } from "../../../config/i18n";
import { ProductProvider } from "../../contexts/ProductContext";
// eslint-disable-next-line import/no-cycle
import { LocaleProvider } from "../../contexts/LocaleContext";

const YM_ACCOUNTS = { ru: 98321419, en: 22871677 };

const PageContextWrapper = ({
  children,
  pageContext: { locale, siteLanguage, localePath },
}) => (
  <LocaleProvider siteLanguage={siteLanguage} locale={locale} localePath={localePath}>
    <ProductProvider>
      <>
        {children}
        <YMInitializer
          accounts={[YM_ACCOUNTS[TARGET_LANGUAGE]]}
          options={{
            webvisor: true,
            defer: true,
            clickmap: true,
          }}
        />
      </>
    </ProductProvider>
  </LocaleProvider>
);

export { PageContextWrapper };
