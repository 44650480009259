import IconButton from "../Buttons/IconButton";
import ArrowLeft from "../Icons/ArrowLeft";
import { useLocaleContext } from "../../contexts/LocaleContext";

import { createClassName } from "../../helpers";
import "./style.scss";

const cn = createClassName("scroll-up");

const ScrollUp = () => {
  const { isMobileOnly } = useLocaleContext();
  const scrollUp = () => {
    window.scrollBy({
      top: 0 - window.pageYOffset,
      behavior: "smooth",
    });
  };

  return (
    <div className={cn("wrap")}>
      <div className={cn("button")}>
        <IconButton
          className={cn("direction", { mobile: isMobileOnly })}
          ariaLabel="scroll up"
          handler={() => scrollUp()}
        >
          <ArrowLeft />
        </IconButton>
      </div>
    </div>
  );
};

export default ScrollUp;
