import "./style.scss";

import { LazyImage, imageManager, noBase64FluidImage, stdFluidImage } from "../components/Image"
import { useMemo } from "react";
import {
  faqDetail,
  faqHeading,
  insideLink,
  renderCom,
} from "../components/ForMarkDownComponents";

import AccentBanner from "../components/AccentBanner";
import Benefits from "../components/Benefits";
import Container from "../components/Container";
import ContainerForTestimonials from "../components/Testimonial/List";
import FeatureReview from "../components/Features";
import Heading from "../components/Heading";
import Helmet from "react-helmet"
import Layout from "../layouts";
import LazyLoad from "react-lazy-load"
import LinkOnResource from "../components/Links/LinkOnResource"
import { useLocaleContext } from "../contexts/LocaleContext";
import MainBanner from "../components/AccentBanner/MainBanner";
import StructureData from "../components/StructuredData";
import Title from "../components/Title";
import { clientsList } from "../constants";
import { createClassName } from "../helpers";
import { graphql } from "gatsby";
import loadable from "@loadable/component";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import { videoManager } from "../components/Video";

const Clients = loadable(() => import("../components/Icons/Clients"));
// const FeatureReview = loadable(() => import("../components/Features"));
// const AccentBanner = loadable(() => import("../components/AccentBanner"));
// const ContainerForTestimonials = loadable(() => import("../components/Testimonial/List"));
// const Benefits = loadable(() => import("../components/Benefits"));

const cn = createClassName("home");
const cnReviews = createClassName("reviews");

const renderComToolkit = {
  h2: faqHeading,
  detail: faqDetail,
  "inside-link": insideLink,
};
const faqRenderCom = renderCom(renderComToolkit);

const Main = ({ data, ...props }) => {
  // const [openFrame, freeTrial] = useState(false);
  const { htmlAst, frontmatter } = useMemo(() => data.markdownRemark, []);

  const { t } = useTranslation("strings");
  const { isMobile: mobile } = useLocaleContext();

  // const handler = useCallback(() => {
  //   freeTrial(true);
  //   const trialBlock = document.querySelector("#get-started");
  //   trialBlock.scrollIntoView({ behavior: "smooth" });
  // }, []);


  // const bannerImagesRenderer = React.useCallback(
  //   imageManager(data.bannerImages, (props) => LazyImage({...props, eager: true, native: true})),
  //   [])
  const bannerImagesRenderer =
    imageManager(
      data.bannerImages,
      (props) => LazyImage({ ...props, native: false })
    )

  const { localePath } = useLocaleContext();

  return (
    <>
      <Helmet>
        {data.bannerImages.nodes.map(({ childImageSharp: img, relativePath }, i) => {
          const regexp = localePath.length > 0
            ? RegExp(`-${localePath}\.`)
            : /^((?!-[a-z]{2}\.).)*$/
          const { srcWebp: src, srcSetWebp: srcSet, sizes } = img.gatsbyImageData.images.fallback;
          return relativePath.match(regexp)
            ? <link key={i} rel="preload" as="image" href={src} imagesrcset={srcSet} imagesizes={sizes} />
            : null
        })}
      </Helmet>

      <Layout
        title={frontmatter.seo[0].title}
        description={frontmatter.seo[0].description}
        link={frontmatter.seo[0].link}
        image={frontmatter.seo[0].image}
      //freeTrialButton
      //buttonHandler={handler}
      >
        <MainBanner
          //buttonHandler={handler}
          heading={frontmatter.bannerTitle}
          bannerSubTitle={frontmatter.bannerSubTitle}
          imageBanners={frontmatter.imagesMainBanner.desktopImages}
          // mobileImage={frontmatter.imagesMainBanner.mobileImage.image}
          imageRenderer={bannerImagesRenderer}
          // mobileImageAlt={frontmatter.imagesMainBanner.mobileImage.alt}
          buttonText={frontmatter.freeTrialButton}
        // buttonText={t("common:start-ft")}
        />

        <Benefits list={frontmatter.benefits} label="XLTools benefits" />

        <LazyLoad
          offsetVertical={100}
          throttle={0}
        >
          <section className="toolkit">
            <Container>
              <Title
                level={2}
                weight="light"
                align="center"
                className={cn("toolkit", { title: true })}
              >
                {frontmatter.toolkit.title}
              </Title>

              <FeatureReview
                featuresReviewList={frontmatter.toolkit.toolkitList}
                imageRenderer={imageManager(data.toolkitImages)}
                videoRenderer={videoManager(data.pageVideos)}
              />
            </Container>
          </section>
        </LazyLoad>

        <LazyLoad
          offsetVertical={100}
          throttle={0}
        >
          <section
            id="get-started"
            aria-label="Get started in no time"
            className={cn("trial-frame")}
          >
            <AccentBanner
              title={t("getStarted.title", { nonBreakingSpace: "\xa0" })}
              titleClassName={cn("accentBanner", { title: "wrap" })}
              headingClassName={cn("accentBanner", { title: true })}
              subtitleClassName={cn("accentBanner", { title: true })}
              subTitle={t("getStarted.subtitle", { nonBreakingSpace: "\xa0" })}
              buttonDescription={t("AccentSecondaryBanner.button-description")}
              className={cn("accentBanner", { mobile: true })}
              buttonClassName={cn("accentBanner", { button: true })}
              imageRenderer={imageManager(data.accentBannerImages)}
            >
              <LinkOnResource
                href="/download-free-trial/"
                isVolume
                color="white"
                className={cn("link", { trial: true })}
                isRel={false}
                withoutLocale={false}
              >
                {t("common:start-ft")}
              </LinkOnResource>
              {/* <Button
              // handler={handler} 
              className={cn("link", { trial: true })}>
              {t("common:start-ft")}
            </Button> */}
            </AccentBanner>
            {/* <Frame
          open={openFrame} 
          /> */}
          </section>
        </LazyLoad>

        <LazyLoad
          offsetVertical={100}
          throttle={0}
        >
          <section aria-label="User reviews" className={cnReviews()}>
            <div className="container-info-block">
              <Container className={cnReviews("wrap")}>
                <Heading
                  level={2}
                  weight="light"
                  align="center"
                  className={cnReviews("title")}
                >
                  {frontmatter.testimonial.title}
                </Heading>
                <ContainerForTestimonials
                  testimonialsList={frontmatter.testimonial.list}
                />
                {/* } */}
                {!mobile &&
                  <div className={cnReviews("clients")}>
                    <ul className={cnReviews("clients-list")}>
                      {clientsList.map(client => (
                        <li
                          key={`${client.alt}_${client.img}`}
                          className={cnReviews("clients-item")}
                        >
                          <Clients icon={client.img} alt={client.alt} />
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              </Container>
            </div>
          </section>
        </LazyLoad>

        <LazyLoad
          offsetVertical={100}
          throttle={0}
        >
          <section aria-label="faq" className="faq">
            <Container className={cn("faq", { wrap: true })}>
              {faqRenderCom(htmlAst)}
            </Container>
          </section>
        </LazyLoad>
      </Layout>

      <StructureData
        url={props.location.pathname}
        language={props.pageContext.siteLanguage}
      />
    </>
  );
};

export default Main;

export const query = graphql`
  query getMainPage($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        seo {
          description
          title
          link
          image
        }
        bannerTitle
        bannerSubTitle
        freeTrialButton
        imagesMainBanner {
          desktopImages {
            image
            alt
            mobileImage
          }
        }
        benefits {
          title
          description
        }
        toolkit {
          title
          toolkitList {
            description
            heading
            beforeHeading
            afterHeading
            img {
              alt
              src
            }
            link
            list {
              content
              title
            }
            subHeading
            textLink
            video {
              src
              type
            }
          }
        }
        testimonial {
          list {
            author
            item
            skill
          }
          title
        }
      }
    }
    bannerImages:allFile(
      filter: {
        relativeDirectory: {
          eq: "images/home-banner"
        },
        extension: { in: ["png", "jpg", "jpeg"] }
      }
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    toolkitImages:allFile(
      filter: {
        relativeDirectory: {
          eq: "images/toolkits"
        },
        extension: { in: ["png", "jpg", "jpeg"] }
      }
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    accentBannerImages:allFile(
      filter:{
        relativeDirectory: { eq: "images"},
        name: { regex:"/^xltools-add-in-for-excel-ribbon/"}    
      }      
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    pageVideos:allFile(
      filter: {
        relativeDirectory: {
          eq: "video/home"
        }
      }
    ) {
      nodes {
        relativePath
        publicURL
        extension
      }
    }
  }
`;
