const { useEffect } = require("react");
const { Helmet } = require("react-helmet");
const ReactGA = require("react-ga/dist/esm/index");
const { CookiesProvider } = require("react-cookie");
const { PageContextWrapper } = require("./src/layouts/PageContextWrapper");

exports.onClientEntry = () => {
  window.onload = () => {
    ReactGA.initialize([
      {
        trackingId: "UA-44791317-1",
        gaOptions: {
          "Cache-Control": "max-age = 86400",
          cookieExpires: 86400,
        },
      },
    ]);
  };
};

exports.wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <Helmet>
      <link
        rel="preconnect"
        href="https://www.google.com/"
        crossOrigin
      />
      <link
        rel="preconnect"
        href="https://www.google.ru/"
        crossOrigin
      />
      <link
        rel="preconnect"
        href="https://stats.g.doubleclick.net"
        crossOrigin
      />
      <link
        rel="preconnect"
        href="https://mc.yandex.ru/webvisor"
        crossOrigin
      />
      <link
        rel="preconnect"
        href="https://www.google-analytics.com/"
        crossOrigin
      />
      <link
        rel="preconnect"
        href="https://www.googletagmanager.com/"
        crossOrigin
      />
    </Helmet>
    {element}
  </CookiesProvider>
);

exports.wrapPageElement = ({ element, props }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      ReactGA.ga("set", {
        page: window.location.pathname,
        title: window.document.title,
      });
      ReactGA.ga("send", {
        hitType: "pageview",
        page: window.location.pathname,
        title: window.document.title,
      });
    }, 400);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, []);

  return <PageContextWrapper {...props}>{element}</PageContextWrapper>;
};
