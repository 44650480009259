const getParts = (price) => {
  const [integerPart, decimalPart] = Number(price).toFixed(2).split(".");

  return {
    integerPart,
    decimalPart: decimalPart === "00" ? undefined : decimalPart,
  };
};

module.exports = { getParts };
