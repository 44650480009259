import { graphql } from "gatsby";
import Purchase from "../purchase";

const PurchaseUpgrade = ({ data }) => <Purchase upgradePage data={data} />;

export default PurchaseUpgrade;

export const query = graphql`
  query getPurchaseUpgrade($path: String! $siteLanguage: String!) {
    markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      htmlAst
      frontmatter {
        bannerTitle
        bannerSubTitle
        saleTitle
        seo {
          description
          link
          title
          noForRobots
        }
        compareFooterTitle
        compareFooterDescription
        dataRowList {
          description
          heading
          img
          status
          title
          marker
        }
        benefits {
          title
          description
        }
        priceBlockList {
          best
          description
          title
          productCode
        }
        tableHeadingThList
      }
    }
    allProduct {
      products: nodes {
        productCode
        unitCostInteger
        unitCostDecimal
        promotionCostInteger
        promotionCostDecimal
        upgradeCostInteger
        upgradeCostDecimal
        currency
        discounts {
          minQuantity
          discount
        }
      }
    }
  }
`;
